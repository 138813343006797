import { useState } from "react";
// import emailjs from "emailjs-com";
import React from "react";
import { notification } from "antd";

export const Contact = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleFocus = (e) => {
    e.target.style.height = "auto"; // Reiniciar la altura para evitar el desplazamiento
    e.target.style.height = e.target.scrollHeight + "px"; // Ajustar a la altura correcta
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = "https://mailing.iqmed.audifarmsalud.dev/email";

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, message }),
      });

      if (!response.ok) {
        notification["error"]({
          message: "Error",
          description: "Hubo un error al tratar de enviar su mensaje.",
        });
      } else {
        notification["success"]({
          message: "Operación realizada con éxito",
          description: "Tu mensaje fue enviado!",
        });
        setName("");
        setEmail("");
        setMessage("");
      }
    } catch (error) {
      notification["error"]({
        message: "Error",
        description: "Hubo un error al enviar el mensaje..",
      });
    }
  };
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="section-title">
                <h2>Contáctanos</h2>
                <p>
                  Por favor, rellena el formulario a continuación para enviarnos
                  un correo electrónico y te responderemos lo antes posible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Nombre"
                        required
                        onChange={handleNameChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleEmailChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Mensaje"
                    required
                    onChange={handleMessageChange}
                    onFocus={handleFocus}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Enviar Mensaje
                </button>
              </form>
            </div>
            <div className="col-md-3 col-md-offset-1 contact-info">
              <div className="contact-item">
                <h3>Información de Contacto</h3>
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Dirección
                  </span>
                  {props.data ? props.data.address : "Cargando..."}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Teléfono
                  </span>{" "}
                  {props.data ? props.data.phone : "Cargando..."}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Correo Electrónico
                  </span>{" "}
                  {props.data ? props.data.email : "Cargando..."}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="footer">
        <p>
          Powered© by{" "}
          <a
            href="https://beeneu.dev"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            Beeneu
          </a>
          . Todos los derechos reservados.
        </p>
      </div>
    </div>
  );
};
